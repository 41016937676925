<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > 社会教育</div>
			<el-carousel :interval="5000" arrow="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<div class="banner" @click="toBannerDetail(item,index)"><img :src="item.pic"/></div>
				</el-carousel-item>
			</el-carousel>
			<div class="wapper flex">
				<div class="left">
					<dl>
						<dt>社会教育</dt>
						<dd @click="handleChangeTab(index)" :class="{'checked' : tabIndex == index}" v-for="(item,index) in classifyList" :key="index">{{item.name}} <img src="@/assets/images/jiantou_you.png"/></dd>
					</dl>
				</div>
				<div class="right">
					<div class="top">
						关键词<el-input v-model="keyword" clearable></el-input>
						发布时间
						<el-date-picker
							v-model="date"
							value-format="yyyy-MM-dd"
							type="date">
						</el-date-picker>
						<img @click="toSearch" src="@/assets/images/shaixuan.png" alt="">
					</div>
					<ul class="item">
						<li v-for="(item,index) in list" :key="index" class="flex" @click="toDetail(item.id)">
							<img :src="item.cover" alt="">
							<div class="info">
								<p class="flex_a"><span class="line1">{{item.title}} </span><label>{{item.releaseTime}}</label></p>
								<div>{{item.intro}}</div>
							</div>
						</li>
					</ul>
					<img class="noData" v-if="total == 0" src="../../assets/images/zanwujilu.png"/>
					<el-pagination
						background
						v-if="total > 0"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageNo"
						:page-size="pageSize"
						layout="total, prev, pager, next, jumper"
						:total="total">
					</el-pagination>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				tabIndex: -1,
				classifyList: [],
				keyword: '',
				date: '',
				pageNo: 1,
				pageSize: 5,
				bannerList: [],
				list: [],
				total: null,
				socialEducationClassId: '',
				
				
			}
		},
		created() {
			// this.getClassify()
			// this.getList()
			this.getBanner()
			
		},
		watch: {
			"$store.state.sjIndex": {
				handler(newVal,oldVal) {
					this.tabIndex = newVal - 1
					this.getClassify()
					
				},
				deep: true,
				immediate: true,
		

			}
		},
		mounted() {
			
		},
		methods: {
			getBanner() {
				let formData = new FormData()
					formData.append('type', 4)
				this.$http({
					url: `/bannersApi/queryBannersPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						if(res.data[0]){
							this.bannerList = res.data
						}
						
					}
				});
			},
			handleChangeTab(index) {
				this.tabIndex = index
				this.socialEducationClassId = this.classifyList[index].id
				this.keyword = ''
				this.date = ''
				this.page = 1
				this.getList()
			},
			getClassify() {
				this.$http({
					url: `/socialEducationClassApi/queryAllSocialEducationClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.classifyList = res.data
						this.handleChangeTab(this.tabIndex)

					}
					
				});
			},
			toSearch() {
				this.pageNo = 1
				this.getList()
			},
			getList() {
				let formData = new FormData()
					formData.append('repleaseTime', this.date ? this.date : '')
					formData.append('page', this.pageNo)
					formData.append('limit', this.pageSize)
					formData.append('socialEducationClassId', this.socialEducationClassId)
					formData.append('title', this.keyword)
				this.$http({
					url: `/socialEducationApi/querySocialEducationPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.total = res.count
						this.list = res.data
					}
					
				});
			},
			toBannerDetail(item,index) {
				if(item.includeContent == 1){
					this.$router.push({ path: "/jiaoyu/bannerDetail",query:{index:index}});
				}
			},
			toDetail(id) {
				this.$router.push({ path: "/jiaoyu/detail",query:{id:id}});
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getList()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		
		background: #FFF;
		.banner{
			img{
				height: 300px;
				width: 100%;
			}
		}
		.wapper{
			padding: 30px 60px;
			.left{
				width: 240px;
				font-size: 18px;
				color: #FFF;
				margin-right: 80px;
				dt{
					height: 60px;
					line-height: 60px;
					background: url('../../assets/images/dabiaoqian.png') no-repeat;
					font-size: 24px;
					font-weight: bold;
					padding-left: 30px;
				}

				dd{
					background: #595E63;
					padding: 0 30px 0 50px;
					height: 60px;
					display: flex;
					align-items: center;
					cursor: pointer;
					img{
						width: 20px;
						height: 20px;
						margin-left: auto;
					}
				}
				.checked{
					background: #353535;
				}
			}
			.right{
				flex: 1;
				min-width: 0;
				.top{
					display: flex;
					align-items: center;
					color: #595E63;
					font-size: 16px;
					.el-input{
						width: 160px;
						margin-left: 20px;
						&:nth-of-type(1){
							margin-right: 30px;
						}
					}
					img{
						width: 38px;
						height: 38px;
						margin-left: 20px;
						cursor: pointer;
					}
				}
				.item{
					li{
						color: #ABABAB;
						cursor: pointer;
						border-bottom: 1px dashed #DCDCDC;
						padding: 30px 0;
						img{
							width: 140px;
							height: 100px;
							border-radius: 5px;
							margin-right: 20px;
						}
						.info{
							flex: 1;
							min-width: 0;
							font-size: 16px;
							p{
								margin-bottom: 10px;
								span{
									font-size: 18px;
									color: #333333;
									flex: 1;
									margin-right: 20px;
								}
								label{
									margin-left: auto;
								}
							}
							>div{
								height: 64px;
								overflow: hidden;
							}
						}
						&:hover{
							.info{
								p{
									span{
										color: #A5343C;
									}
								}
							}
						}
					}
				}

			}
		}
	}
	
	
	
}
	
</style>
